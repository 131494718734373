require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
});

const CORE_SERVICE_API = process.env.CORE_SERVICE_API;
const USER_SERVICE_API = process.env.USER_SERVICE_API;
const EVENT_SERVICE_API = process.env.EVENT_SERVICE_API;
const KOLABORASI_SERVICE_API = process.env.KOLABORASI_SERVICE_API;
const ACADEMY_SERVICE_API = process.env.ACADEMY_SERVICE_API;
const PAYMENT_SERVICE_API = process.env.PAYMENT_SERVICE_API;
const BASE_URL = process.env.BASE_URL;
const TOKEN_KEMENAG = process.env.TOKEN_KEMENAG;
const BASE_URL_API_KEMENAG = process.env.BASE_URL_API_KEMENAG;

export default {
  CORE_SERVICE_API,
  USER_SERVICE_API,
  EVENT_SERVICE_API,
  KOLABORASI_SERVICE_API,
  ACADEMY_SERVICE_API,
  PAYMENT_SERVICE_API,
  BASE_URL,
  TOKEN_KEMENAG,
  BASE_URL_API_KEMENAG,
};
